import { equalsIgnoreCase } from './functions'

function applyMandatoryRatingAgencies(venueWineObject, ratingAgencies) {
  let mandatoryRatingAgencies = getMandatoryRatingAgencies(ratingAgencies)
  if ( venueWineObject.attributes.ratings == null) {
    venueWineObject.attributes.ratings = []
  }
  for (let a = 0; a < mandatoryRatingAgencies.length; a++) {
    const ratingAgencyId = mandatoryRatingAgencies[a].id;
    const defaultRatingValue = mandatoryRatingAgencies[a].default
    venueWineObject.attributes.ratings.push({
        value: defaultRatingValue,
        ratingAgency: ratingAgencyId
    })
  }
}

function getMandatoryRatingAgencies(ratingAgencies) {
  var mandatoryRatingAgencies = []
  ratingAgencies.data.forEach((ratingAgency) => {
              if(ratingAgency.attributes.mandatory === true) {
                  var mandatoryRatingAgency = {
                      id: ratingAgency.id,
                      default: ratingAgency.attributes.default,
                  }
                  mandatoryRatingAgencies.push(mandatoryRatingAgency)
              }
  })
  return mandatoryRatingAgencies
}

function  calcPurchasePriceAvg(purchasePrices) {
  var priceSum = 0.0;
  if (purchasePrices != null && purchasePrices.length > 0) {
    purchasePrices.forEach((element) => {
      priceSum += element.price;
    });
    priceSum = priceSum / purchasePrices.length;
    priceSum = Math.round(priceSum * 100) / 100;
  }
  return priceSum;
}

function addSupplierAndPrice(venueWine, price, supplier) {
  if (venueWine.attributes["purchasePrices"] == null) {
    venueWine.attributes["purchasePrices"] = []
  }
  const foundPurchasePrice = venueWine.attributes["purchasePrices"].find(
    (pp) => pp.price === price
  );
  if (!foundPurchasePrice) {
    var purchasePriceBody = {
      price: price,
      wineSupplier: supplier["id"],
    };
    venueWine.attributes["purchasePrices"] = []
    venueWine.attributes["purchasePrices"].push(purchasePriceBody);
  } else {
    foundPurchasePrice.wineSupplier = supplier["id"];
  }

  venueWine.attributes["purchasePriceAvg"] = calcPurchasePriceAvg(
    venueWine["purchasePrices"]
  )
  return venueWine
}

function getWineNameNullChecked(title, grapeVariety) {
  if (title == null || title == "" || title.trim() == "") {
    return grapeVariety
  }
  return title
}

function matchVenueWineWithEveryColumn(identifierKeys, offlineVenueWine, csvWine, identifierToVenueWineKey,identifierMappingObject, removeIdentifierFromMatichArrayCallBack){
  var isMatching = true;
  for (var i = 0; i <  identifierKeys.length; i++) {
    var identifierKey = identifierKeys[i];
    var venueWineIdentifier = identifierToVenueWineKey[identifierKey];
    var identifierCSVColumn = identifierMappingObject[identifierKey];
 
    var valueA = deepValueOfObject(offlineVenueWine, venueWineIdentifier);
    var valueB = csvWine[identifierCSVColumn];
    isMatching = matchValues(valueA, valueB)
    if (!isMatching){
      // console.log('-- NO match for ' + identifierKey + ' ' + stringA + ' ' + stringB);
      return false
    } else {

      removeIdentifierFromMatichArrayCallBack(identifierKey);
      // console.log('** match ' + identifierKey + ' ' + csvWine[identifierCSVColumn] + ' ' + this.deepValueOfObject(offlineVenueWine, venueWineIdentifier));
    }
  }
  return isMatching
}
function generateWineNameFromColumns(csvWine, identifierMappingObject){
  var wineName = ""
  var values = []
  for (var key in identifierMappingObject) {
    if (values.indexOf(csvWine[identifierMappingObject[key]]) > -1){
      continue
    }
    values.push(csvWine[identifierMappingObject[key]])
    wineName += csvWine[identifierMappingObject[key]] + " "
  }
  return wineName
}

function generateWineString(venueWine){
  var wineName = ""
  if (venueWine.attributes != null && venueWine.attributes.wine != null ) {
    wineName = venueWine.attributes.wine.data.attributes.winery.data.attributes.title + ", " + venueWine.attributes.title + ", " + venueWine.attributes.year + ", "+  venueWine.attributes.bottleSize.data.attributes.amount
  } else {
    wineName = venueWine.wine.winery.title + ", " + venueWine.title + ", " + venueWine.year + ", "+  venueWine.bottleSize.amount
  
  }
  return wineName
}

function matchValues(valueA, valueB) {
  var stringA = valueA;
  var stringB = valueB;
  
  if (Number.isInteger(valueA) && Number.isInteger(valueB)) {
    valueA = parseInt(valueA)
    valueB = parseInt(valueB)
    return valueA === valueB;
  } else if (valueA == 0 && valueB == 0) {
    return true
  } else if (valueA && valueB) {
    stringA = stringA.toString().toLowerCase().replace(",", ".").trim();
    stringB = stringB.toString().toLowerCase().replace(",", ".").trim();
    return equalsIgnoreCase(stringA,stringB);
  } else {
    return false
  }
}

function deepValueOfObject(object, path) {
  var pathArray = path.split('.');
  var value = object;
  pathArray.forEach((path) => {
    value = value[path];
  });
  return value;
}

function addNestedStrapiAttributesToObjects(object) {
  var returnObject = {
    data: {
      attributes: {}
    }
  }
  if (typeof object === 'object' && !Array.isArray(object) && object !== null) {
    for (var key in object) {
      if (key == 'id') {
        returnObject.data.id = object[key]
        continue
      }
      returnObject.data.attributes[key] = addNestedStrapiAttributesToObjects(object[key])
    }
  }
  else {
    returnObject = object
  }
  return returnObject
}

export {
  applyMandatoryRatingAgencies,
  getMandatoryRatingAgencies,
  calcPurchasePriceAvg,
  addSupplierAndPrice,
  getWineNameNullChecked,
  matchVenueWineWithEveryColumn,
  deepValueOfObject,
  matchValues,
  generateWineNameFromColumns,
  generateWineString,
  addNestedStrapiAttributesToObjects
}